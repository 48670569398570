<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col col="11">
        <div class="text-h5 font-weight-bold">
          Original Files
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align-content="center">
      <v-card width="1600px" flat>
        <v-row justify="space-around">
          <v-col cols="11">
            <div class="text-h6 font-weight-bold mx-3">
              Upload your profile
            </div>
          </v-col>
          <v-col cols="1">
            <v-card-actions>
              <v-btn
                @click="enableEditMode"
                v-if="!editMode.originalFiles"
                class="mx-3"
                color="primary"
                style="background-color:blue"
                >Edit</v-btn
              >

              <v-btn
                v-else-if="editMode.originalFiles && !trainerOriginalFiles.id"
                class="mx-3"
                color="primary"
                slot="activator"
                style="background-color:blue"
                @click="createOrginalFiles"
                >Save</v-btn
              >

              <v-btn
                v-else
                @click="updateOriginalFiles"
                color="primary"
                slot="activator"
                style="background-color:blue"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <DeleteAlertBox :dialog="deleteAlertBoxSatus" @onOk="confirmDelte" @onCancel="onCancel"/>
        <v-row class="mx-1" v-if="editMode.originalFiles">
          <UploadDoc type="ORIGINAL_PROFILE" :allowMultiple="true" />
        </v-row>
        <v-row justify="center">
            <!-- <PulseLoader color="#283593" :loading="loading"/> -->
        </v-row>
        
        <div v-if="trainerOriginalFiles.trainerProfileUrl.length != 0">
          <v-row>
            <v-col cols="3">
              <div class="text-h7 font-weight-bold mx-4">
                Trainer profile files
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col>
              <v-list
                v-for="(profileFiles,
                index) in trainerOriginalFiles.trainerProfileUrl"
                :key="index"
              >
                <v-card outlined flat width="450px">
                  <v-row>
                    <v-col cols="11">
                      <div
                        class="text-h7 font-weight-bold mx-2 my-n1 text--primary"
                      >
                        <a
                          @click="
                            downLoadFile('ORIGINAL_PROFILE', profileFiles)
                          "
                        >
                          {{ getFileName(profileFiles) }}
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="1" class="mx-n2 my-n2">
                      <v-icon color="red" @click="deleteFile('ORIGINAL_PROFILE',profileFiles)" v-if="editMode.originalFiles">
                        delete
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col col="11">
            <div
              class="text-h6 font-weight-bold mx-3"
              v-if="editMode.originalFiles"
            >
              Admin Upload Ot Detailed Template/Trainer Related Files
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mx-1" v-if="editMode.originalFiles">
          <UploadDoc type="ORIGINAL_TEMPLATE" :allowMultiple="true" />
        </v-row>
        <div v-if="trainerOriginalFiles.trainerDetailedTemplateUrl.length != 0">
          <v-row>
            <v-col cols="3">
              <div class="text-h7 font-weight-bold mx-4">
                Trainer miscellaneous files
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col>
              <v-list
                v-for="(profileFiles,
                index) in trainerOriginalFiles.trainerDetailedTemplateUrl"
                :key="index"
              >
                <v-card outlined flat  width="450px">
                  <v-row>
                    <v-col cols="11">
                      <div
                        class="text-h7 font-weight-bold mx-2 my-n1 text--primary"
                      >
                        <a
                          @click="
                            downLoadFile('ORIGINAL_TEMPLATE', profileFiles)
                          "
                        >
                          {{ getFileName(profileFiles) }}
                        </a>
                      </div>
                    </v-col>
                    <v-col cols="1" class="mx-n2 my-n2">
                      <v-icon color="red" @click="deleteFile('ORIGINAL_TEMPLATE',profileFiles)" v-if="editMode.originalFiles">
                        delete
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-row>
    <v-row>
      <span :class="editMode.originalFiles ? activeClass : nonActive">
        Admin comments
      </span>
    </v-row>
    <v-row class="mx-n6">
      <AdminComments
        :showAddButton="editMode.originalFiles"
        type="ORIGINAL_FILES"
      />
    </v-row>
  </v-container>
</template>

<script>
//import { PulseLoader } from '@saeris/vue-spinners'
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import UploadDoc from "../../../components/uploadDoc.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import { mapState } from "vuex";
import DeleteAlertBox from '../../../components/originafilesAlertBox.vue'
export default {
  name: "OriginalFiles",
  components: {
    SecondaryNavigation,
    UploadDoc,
    AdminComments,
    DeleteAlertBox
    //PulseLoader
  },
  data() {
    return {
      activeClass: "text-h6 font-weight-bold mx-3 my-2",
      nonActive: "text-h6  font-weight-bold my-2"
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      trainerOriginalFiles: "trainerOriginalFiles",
      editMode: "editMode",
      deleteAlertBoxSatus:'deleteAlertBoxSatus',
      afterOk:'afterOk'
    })
  },
  created() {
    this.$store.dispatch(
      "trainerProfile/getOriginalFiles",
      this.profile.trainerId
    );
  },
  methods: {
    createOrginalFiles() {
      let modifiedOriginalFiles = this.trainerOriginalFiles;
      modifiedOriginalFiles.trainerId = this.profile.trainerId;
      console.log("modifile original files ", modifiedOriginalFiles);
      this.$store.dispatch(
        "trainerProfile/createOriginalFiles",
        modifiedOriginalFiles
      );
    },
    updateOriginalFiles() {
      this.$store.dispatch(
        "trainerProfile/updateOrginalFiles",
        this.trainerOriginalFiles
      );
    },
    getFileName(s3Name) {
      let splitArray = s3Name.split("/");
      splitArray = splitArray[splitArray.length - 1];
      splitArray = splitArray.split("_");
      splitArray = splitArray[splitArray.length - 1];
      return splitArray;
    },
    downLoadFile(type, profileFiles) {
      console.log(type, profileFiles);
      this.$store.dispatch("trainerProfile/downloadAFile", {
        type: type,
        fileUrl: profileFiles
      });
    },
    deleteFile(type, profileFiles) {
      console.log('file to be deleted ' ,profileFiles);
      this.$store.dispatch("trainerProfile/deleteAFile", {
        type: type,
        fileUrl: profileFiles,
        deleteConfirmation:false,
      });
    },
    enableEditMode() {
      let editMode = this.$store.dispatch(
        "trainerProfile/changeEditMode",
        "ORIGINAL_FILES"
      );
      console.log("edmit mode return ", editMode);
    },
    confirmDelte(){
      this.$store.dispatch("trainerProfile/deleteAFile", {
        type: this.afterOk.type,
        fileUrl: this.afterOk.fileUrl,
        deleteConfirmation:true,
      });
    },
    onCancel(){
      this.$store.dispatch('trainerProfile/closeDeleteDailog')
    }
  }
};
</script>

<style></style>
