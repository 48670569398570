<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <v-card elevation="1">
      <v-card-title class=" text-h5  font-weight-bold justify-center" >
        <div class="text-h5  font-weight-bold red--text">
        Alert
        </div>
        </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-h7  font-weight-bold">
        You are about to delete a file , are you sure you want to delete the file ?</div>
</v-card-text>    
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background-color:white"  outlined  @click.native="$emit('onCancel')">Cancel</v-btn>
        <v-btn color="red darken-3" outlined  @click.native="$emit('onOk')" >Delete File</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name:"fileDeleteDailog",
  props: ["dialog"]
}
</script>